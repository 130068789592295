import React, { useState, useEffect } from "react"
import { View, Text, TouchableOpacity, ScrollView, Image, Linking, SafeAreaView} from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, deleteDoc, doc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import styles from "./style";


export default function ConsultaCaso1({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail]= useState("");

    const [retorno, setRetorno] = useState([]);

    useEffect(() => {
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail(profile.email)
            });
        } 
    },[]);


    async function Caso1() {
                  
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("numeroCaso", "==", '1'), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ["DAO", "Intercept", "Formula", "Intercept Course"]));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                status: doc.data().status,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente
            };
            array.push(Obj)
        });

        setRetorno(array)
}

useEffect(() => {
    Caso1();
}, [email])
   
    return (

        <LinearGradient style={styles.container}
        colors={['#113262', '#0D0D0D']}
        >
            <SafeAreaView>
                <View style={styles.header}>
                        <View>
                            <Text style={styles.Title}>Consulta de Caso Clínico</Text>
                    </View>

                        <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                            <MaterialCommunityIcons
                                name="reply"
                                size={40}
                                color="#FFF"
                                style={{justifyContent: 'center', alignContent: 'center' }}
                                />
                        </TouchableOpacity>
                </View>

                <ScrollView style={{flex: 1}}>

                {retorno && retorno.map((item, index) => {
                return(
                    <View key={index}>
                        <Text style={styles.descricao}>
                            Cualquier duda, pueden enviar un mensaje: +55 14 99620-7009 
                        </Text>

                        {item.status !== "Concluido" ?
                        <Text style={styles.textStatus}>
                            Status: 🟠 {item.status}
                        </Text>
                        :
                        <Text style={styles.textStatus}>
                            Status: 🟢 {item.status}
                        </Text>
                        }

                        {item.status === "Concluido" &&
                        <Text style={styles.descricao}>
                            Consulte su email para ver la resolución de su caso clínico
                        </Text>
                        }

                        <Text style={styles.descricao3}>
                            Adjunta el Guía de Anamnesis
                        </Text>

                        <TouchableOpacity style={styles.buttomFoto} 
                        onPress={() => {Linking.openURL(item.Anamnesis)}}>
                            <MaterialCommunityIcons
                                name="file-export"
                                size={24}
                                color="#000" />

                            <Text style={styles.textBotao}> Ver archivo
                            </Text>
                        </TouchableOpacity>
                        
                    
                        <Text style={styles.descricao4}>
                        Adjunta la documentación completa del paciente (PowerPoint)
                        </Text>

                        
                        <TouchableOpacity style={styles.buttomFoto}
                        onPress={() => {Linking.openURL(item.DocPaciente)}}>
                            <MaterialCommunityIcons
                                name="file-export"
                                size={24}
                                color="#000" />

                            <Text style={styles.textBotao}> Ver archivo
                            </Text>
                        </TouchableOpacity>
                    
                        
                        

                
                        <Text style={styles.descricao4}>
                        Adjunta la cefalometría del paciente (PDF - WebCeph)
                        </Text>

                        <TouchableOpacity style={styles.buttomFoto} 
                        onPress={() => {Linking.openURL(item.CefalometriaPaciente)}}>
                            <MaterialCommunityIcons
                                name="file-export"
                                size={24}
                                color="#000" />

                            <Text style={styles.textBotao}> Ver archivo
                            </Text>
                        </TouchableOpacity>

                        {item.status === "Reprobado por el soporte" ?
                        <TouchableOpacity style={styles.buttomEnviar} onPress={async () => {
                            const db = getFirestore();
                            await deleteDoc(doc(db, "Resumos", item.id)), 
                            navigation.navigate("Caso1COE")}}>

                            <Text style={{color: "#000000", textAlign: 'center'}}>
                                Enviar nuevamente
                            </Text>

                        </TouchableOpacity>
                        :
                        <View></View>
                        }


                    {item.status === "Reprobado por los profesores" ?
                        <TouchableOpacity style={styles.buttomEnviar} onPress={async () => {
                            const db = getFirestore();
                            await deleteDoc(doc(db, "Resumos", item.id)), 
                            navigation.navigate("Caso1COE")}}>

                            <Text style={{color: "#000000", textAlign: 'center',}}>
                                Enviar nuevamente
                            </Text>

                        </TouchableOpacity>
                        :
                        <View></View>
                        }
                    </View>
                )
                })};

                </ScrollView>
            </SafeAreaView>
        </LinearGradient>

    );


} 